import React from 'react';
import { NavLink } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import useLoginView from '../hooks/useLoginView';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
  const {
    isAuthed,
    password,
    doLogin,
    profile,
    login,
    logOut,
  } = useLoginView();
  return (
    <div>
      <ToastContainer />
      <NavLink to="/">Home</NavLink>
      <h1>
        {'Logged In: '}
        {isAuthed.toString()}
      </h1>
      <input
        ref={password}
        type="password"
      />
      <button
        type="button"
        value="Authenticate"
        onClick={() => {
          doLogin();
        }}
      >
        Submit
      </button>
      {isAuthed && <button onClick={() => logOut()} type="button">Log out</button>}
      <div>
        <h2>Google Login</h2>
        {profile?.id ? (
          <div>
            <img src={profile.picture} alt="user" />
            <h3>User Logged in</h3>
            <p>
              Name:
              {profile.name}
            </p>
            <p>
              Email Address:
              {profile.email}
            </p>
            <br />
            <br />
            <button onClick={logOut} type="button">Log out</button>
          </div>
        ) : (
          <button onClick={() => login()} type="button">Sign in with Google</button>
        )}
      </div>
    </div>
  );
};

export default Login;
