import { useState, useEffect } from 'react';
import { REST_ENDPOINT } from '../constants';

function useYearData() {
  const [yearDataSet, setYearData] = useState();
  const [dowDataSet, setDowData] = useState();
  const [monthDataSet, setMonthData] = useState();

  useEffect(() => {
    (async () => {
      console.log('Report#useEffect :');
      const endpoint = `${REST_ENDPOINT}/index.php?cmd=graph_by_viewed_yr`;
      const response = await fetch(endpoint);

      if (!response.ok) {
        console.log('response.status :', response.status);
        alert(`loading error : ${response.status}`);
      } else {
        const data = await response.json();

        console.log('reportData :>> ', data);

        if (data !== null) {
          const yearData = {
            labels: data.yearsViewed.map(x => x.value),
            datasets: [
              {
                label: 'Dataset 1',
                data: data.yearsViewed.map(x => x.count),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          };
          setYearData(yearData);

          setDowData({
            labels: data.weekdayBreakdown.map(x => x.value),
            datasets: [
              {
                label: 'Dataset 1',
                data: data.weekdayBreakdown.map(x => x.count),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });
          const monthData = {
            labels: data.monthBreakdown.map(x => x.value),
            datasets: [
              {
                label: 'Dataset 1',
                data: data.monthBreakdown.map(x => x.count),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          };
          setMonthData(monthData);
        }
      }
    })();
  }, []);
  return { yearDataSet, dowDataSet, monthDataSet };
}

export default useYearData;
