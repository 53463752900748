import { useEffect, useState } from 'react';
import { REST_ENDPOINT } from '../constants';

const useApp = () => {
  const [globalContext, setGlobalContext] = useState(null);
  useEffect(() => {
    (async () => {
      console.log('App.jsx: useEffect');
      if (!globalContext) {
        try {
          const response = await fetch(
            `${REST_ENDPOINT}/index.php?cmd=settings`,
            {
              method: 'GET',
              mode: 'cors',
            },
          );

          if (response.ok) {
            const results = await response.json();
            setGlobalContext(results);
          }
        } catch (error) {
          console.error('Error: ', error);
          throw new Error('Settings not loading correctly');
        }
      }
    })();
  }, []);

  return { globalContext };
};
export default useApp;
