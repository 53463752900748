import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import useMovieEdit from '../hooks/useMovieEdit';
import MyContext from './MyContext';
import ImdbForm from './ImdbForm';
import {
  MOBILE_BREAKPOINT,
  STORAGE_KEY,
} from '../constants';

import './MovieEdit.css';

const MovieEdit = ({ movie, onClose }) => {
  console.log(`edit Movie ${JSON.stringify(movie)}`);
  const { UPLOAD_ROOT } = useContext(MyContext);
  const isMobile = window.innerWidth < MOBILE_BREAKPOINT;
  const {
    movieRef, formRef, deleteMovie, saveMovie, isLoading,
  } = useMovieEdit(movie, onClose);

  function closeSideBar() {
    onClose({ action: 'CANCEL' });
  }

  const isAuthed = window.localStorage.getItem(STORAGE_KEY) !== 'undefined'
    && window.localStorage.getItem(STORAGE_KEY) !== 'null';

  return (
    <>
      <form onSubmit={e => saveMovie(e)} ref={formRef}>
        <button
          className="btn btn-warning btn-close"
          onClick={() => closeSideBar()}
          type="button"
        >
          x
        </button>
        <img
          src={`${UPLOAD_ROOT}/${movie.imdbImageId || 'not_yet_available'}.jpg`}
          className="detailsPoster"
          alt={movie.title}
          title={movie.id}
        />
        <fieldset className="movie-form">
          <section className="form-row">
            <label>Title</label>
            <input
              type="text"
              name="title"
              defaultValue={movie.title}
              data-testid="titleInput"
            />

          </section>
          <section className="form-row">
            <label>Viewed</label>
            <input
              type="date"
              name="dtViewed"
              defaultValue={movie.dtViewed}
            />
          </section>
          <section className="form-row">
            <label>Comments</label>
            <textarea
              name="comments"
              defaultValue={movie.comments}
            />
          </section>
          <section className="form-row">
            <label>Rating</label>
            <input
              type="text"
              name="rating"
              defaultValue={movie.rating}
            />
          </section>
          <section>
            <label>Disk</label>
            <input
              type="text"
              name="disk"
              defaultValue={movie.disk}
            />
          </section>

          <button
            className="btn btn-primary btn-submit save"
            data-testid="editBtn"
            type="submit"
            disabled={isLoading || !isAuthed}
          >
            <i className="fa fa-file" />
            {' Submit '}
          </button>

          {isAuthed && (
            <button
              onClick={deleteMovie}
              className="btn btn-danger deleteBtn"
              type="button"
            >
              <i className="fa fa-trash" />
              {' Delete '}
            </button>
          )}
        </fieldset>
      </form>
      <div>
        {isAuthed && (
          <ImdbForm
            movieId={movie.id}
            onSuccess={movieUpdate => {
              console.log('movieupdate', movieUpdate);
              // movieRef.current = { ...movieRef.current, movieUpdate };
              movieRef.current.imdbId = movieUpdate.imdbId;
              movieRef.current.title = movieUpdate.title;
              movieRef.current.dtReleasedFull = movieUpdate.dtReleasedFull;
              movieRef.current.imdbGenre = movieUpdate.imdbGenre;
              movieRef.current.runtime = movieUpdate.runtime;
              movieRef.current.storyline = movieUpdate.storyline;
              movieRef.current.imdbRating = movieUpdate.imdbRating;
              movieRef.current.imdbImageId = movieUpdate.imdbImageId;
              console.log('movieRef.current', movieRef.current);
              onClose({ action: 'EDIT', movie: movieRef.current });
            }}
          />
        )}

        <fieldset className="movie-form movie-details">
          {!isMobile && (
            <section>
              <label>Torrent search </label>
              {movie.imdbId && (
                <a
                  href={`https://rarbg.to/torrents.php?imdb=${movie.imdbId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-magnet inline-margin" />
                  <span>by IMDB id</span>
                </a>
              )}
            </section>
          )}
          <section>
            <label>Released</label>
            <span>{movie.dtReleasedFull}</span>
          </section>
          <section>
            <label>Runtime</label>
            <span>{`${movie.runtime} min`}</span>
          </section>
          <section>
            <label>IMDB Id</label>
            <span className="text-imdb">
              {`${movie.imdbId}, ${movie.imdbRating}`}
            </span>
            {movie.imdbId !== '' && (
              <a
                href={movie.imdbId ? `https://www.imdb.com/title/${movie.imdbId}/` : 'https://www.imdb.com/'}
                title="Visit imdb.com"
                className="imagelink sprite-imdb"
                target="_new"
              >
                imdb.com
              </a>
            )}
          </section>
          <section>
            <label>Genre: </label>
            <span>{movie.imdbGenre}</span>
          </section>
          <section>
            <label>Storyline</label>
            <span>{movie.storyline}</span>
          </section>
          <section>
            <label>Rotten Tomatoes: </label>
            <span>{`${movie.rtTmeterRating}, ${movie.rtCommunityRating}`}</span>
            {movie.rtId !== '' && (
              <a
                href={`https://www.rottentomatoes.com/m/${movie.rtId}`}
                title="rotten tomatoes"
                className="imagelink sprite-rottentomatoes"
                target="_new"
              >
                rottentomatoes.com
              </a>
            )}
            {/*
          #TODO
          <button onClick={resaveImdbInfo} disabled type="button">
            Reload
          </button> */}
          </section>
          <span>
            Movie Id:
            {movie.id}
          </span>
        </fieldset>
      </div>
    </>
  );
};

export default MovieEdit;

MovieEdit.propTypes = {
  movie: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
