import React from 'react';
import PropTypes from 'prop-types';
import { REST_ENDPOINT, STORAGE_KEY } from '../constants';

const DownloadedBtn = ({ id, onUpdate }) => {
  const markAsDownloaded = async () => {
    console.log('markAsDownloaded :', id);
    const endpoint = `${REST_ENDPOINT}/index.php?cmd=manage_movie&action=mark_as_downloaded&movie_id=${id}`;
    const config = {
      headers: {
        'X-App-Token': window.localStorage.getItem(STORAGE_KEY) || '',
      },
    };
    const response = await fetch(endpoint, config);
    if (!response.ok) {
      // TODO: change to toast.error
      alert('Error : Mark As Downloaded');
    }
    onUpdate();
  };

  return (
    <button type="button" onClick={markAsDownloaded} className="actionBtn">
      <i
        className="fa fa-cloud-download inline-margin"
        title="mark as downloaded"
      />
      <span>Downloaded</span>
    </button>
  );
};
export default DownloadedBtn;

DownloadedBtn.propTypes = {
  id: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
