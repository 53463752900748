import {
  useState, useRef, useEffect, useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import useForm from './useForm';

import { REST_ENDPOINT } from '../constants';

const DEBOUNCE_TIME = 300;

function debounce(func, wait, immediate) {
  let timeout;
  return () => {
    const context = this;
    const args = arguments;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

const useMovieListView = () => {
  const navigate = useNavigate();
  const pageRef = useRef(1);
  const [searchFormRef, form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [movies, setMovies] = useState([]);
  const [navdata, setNavdata] = useState({});
  const [viewed, setViewed] = useState(false);

  const prevBtnDisabled = useMemo(() => navdata.prev === '', [navdata]);
  const nextBtnDisabled = useMemo(() => navdata.next === '', [navdata]);

  /** Page Data look up */
  async function loadMovies() {
    const searchParam = form.get('searchParam');
    console.log('loadMovies#searchParam:', searchParam);
    let endpoint = `${REST_ENDPOINT}/index.php?cmd=movies`;
    console.log(
      `searchParam.length : ${searchParam?.length}`,
    );

    const hasSearch = searchParam.length > 0;
    console.log(`hasSearch : ${hasSearch}`);
    console.log(`viewed : ${viewed}`);
    const currentPage = pageRef.current?.value || 1;

    if (!viewed) {
      endpoint += '&unviewed=true';
    } else if (hasSearch) {
      endpoint += `&s=${searchParam}&page=${currentPage}`;
    } else {
      endpoint += `&page=${currentPage}`;
    }
    setIsLoading(true);
    try {
      const response = await fetch(endpoint);
      console.log('response :', response);
      if (!response.ok) {
        console.log('response.status :', response.status);
        throw new Error(response.status);
      } else {
        const data = await response.json();
        console.log('data :', data);

        setMovies(data.movies);
        setNavdata({ ...data.navdata, page: data.page });
        pageRef.current.value = data.page;
      }
    } catch (err) {
      console.log(`Error: ${err}`);
      toast.error(`loading error : ${err}`);
    } finally {
      setIsLoading(false);
    }
  }

  /** Search functions */
  const debouncedLoadMovies = debounce(loadMovies, DEBOUNCE_TIME);

  /** View functions */
  function toggleViewed() {
    console.log(`toggle view called ${viewed}`);
    setViewed(!viewed);
  }

  /** Movie functions */

  const movieAdded = () => {
    setViewed(false);
    debouncedLoadMovies();
  };

  /** Page Nav functions */

  function goPageDirection(offset) {
    pageRef.current.value = parseInt(pageRef.current.value, 10) + offset;
    debouncedLoadMovies();
  }

  function checkKeyPressed(e) {
    console.log(`handle key presss ${e.key}`);

    if (e.altKey && e.key === 'v') {
      console.log('change View mode');
      toggleViewed();
    } else if (e.altKey && e.key === 'a') {
      // Note: getting element by id is a hack; IDKW
      const field = document.getElementById('addField');
      field.focus();
      e.preventDefault();
    } else if (e.altKey && e.key === 'm') {
      console.log('home');
      navigate('/');
    } else if (e.altKey && e.key === 'l') {
      console.log('logs');
      navigate('/logs');
    } else if (e.altKey && e.key === 'y') {
      console.log('year report');
      navigate('/yearReport');
      e.preventDefault();
    } else if (e.altKey && e.key === 't') {
      console.log('timelineReport');
      navigate('/timelineReport');
    } else if (e.altKey && e.key === 'f') {
      // Note: getting element by id is a hack; IDKW
      const field = document.getElementById('searchField');
      field.focus();
      e.preventDefault();
    }
  }

  useEffect(() => {
    (async () => {
      console.log(`useEffect ${viewed}`);
      loadMovies();
      document.addEventListener('keydown', checkKeyPressed);
      return () => document.removeEventListener('keydown', checkKeyPressed);
    })();
  }, [pageRef.current, viewed]);

  return {
    navdata,
    pageRef,
    viewed,
    searchFormRef,
    form,
    prevBtnDisabled,
    nextBtnDisabled,
    isLoading,
    movies,
    goPageDirection,
    movieAdded,
    toggleViewed,
    debouncedLoadMovies,
  };
};

export default useMovieListView;
