import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  REST_ENDPOINT,
  STORAGE_KEY,
} from '../constants';

const ImdbForm = ({ movieId, onSuccess }) => {
  const imdbInput = useRef();
  const [searchResults, setSeaarchResults] = useState([]);
  const token = window.localStorage.getItem(STORAGE_KEY);
  const [imdbLoading, setImdbLoading] = useState(true);

  const getImdbId = () => {
    const id = imdbInput.current.value;
    console.log('id :', id);
    const regexp = /\/title\/([A-Za-z0-9]*)\//;

    const matches = id.match(regexp);
    const saveImdbId = matches?.length ? matches[1] : id;
    return saveImdbId;
  };

  const saveImdbInfo = async () => {
    setImdbLoading(true);
    const saveImdbId = getImdbId();
    const imdbRegex = /[a-z0-9]{9}/;
    console.log('saveImdbId :', saveImdbId);
    if (!saveImdbId.match(imdbRegex)) {
      // TODO: change to toast
      alert(`saveImdbId ${saveImdbId}`);
      return;
    }

    const endpoint = `${REST_ENDPOINT}/index.php?cmd=movie_populate_imdb_info&movie_id=${movieId}&imdb_id=${saveImdbId}/`;
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-App-Token': token,
      },
    };

    try {
      const response = await fetch(endpoint, config);
      if (!response.ok) {
        console.error('response.status :', response.status);
        throw new Error(response.status);
      }
      console.log(response);
      const data = await response.json();
      setImdbLoading(false);
      onSuccess(data.movie);
    } catch (err) {
      console.log(`Error: ${err}`);
      // toast.error(`loading error : ${err}`);
    }
  };

  const searchImdb = async () => {
    let searchParam = '';
    if (imdbInput?.current.value !== '') {
      searchParam = `&title=${imdbInput.current.value}`;
    }
    const endpoint = `${REST_ENDPOINT}/index.php?cmd=movie_search_imdb_id&movie_id=${movieId}${searchParam}`;
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-App-Token': token,
      },
    };

    try {
      setImdbLoading(true);
      const response = await fetch(endpoint, config);
      if (!response.ok) {
        console.error('response.status :', response.status);
        throw new Error(response.status);
      }
      console.log(response);
      const data = await response.json();
      console.log(data);
      const modifiedResult = data.imdbLinks.map(x => ({
        ...x,
        imdbId: x.id.replace('/title/', '').replace('?ref_=fn_al_tt_1', ''),
      }));
      setSeaarchResults(modifiedResult);
      setImdbLoading(false);
    } catch (err) {
      console.log(`Error: ${err}`);
      // toast.error(`loading error : ${err}`);
    }
  };

  function checkKeyPressed(e) {
    console.log(`handle key presss ${e.key}`);
    if (e.altKey && e.key === 'i') {
      const saveImdbId = getImdbId();
      if (saveImdbId) {
        console.log('saveImdbInfo');
        saveImdbInfo();
      } else {
        console.log('searchImdb');
        searchImdb();
      }
    }
  }

  useEffect(() => {
    (async () => {
      console.log('useEffect');

      document.addEventListener('keydown', checkKeyPressed);
      return () => document.removeEventListener('keydown', checkKeyPressed);
    })();
  }, []);

  return (
    <>
      <fieldset className="movie-form">
        <section className="form-row">
          <label>IMDB Id</label>
          <input
            type="text"
            name="imdbId"
            ref={imdbInput}
            placeholder="IMDB ID"
          />
          <button
            type="button"
            onClick={() => saveImdbInfo()}
            className="save"
            disabled={imdbLoading}
          >
            IMDB Save
          </button>
          <button type="button" onClick={() => searchImdb()}>
            searchImdb
          </button>
        </section>
      </fieldset>
      {imdbLoading && <h1>... Searching IMDB ...</h1>}
      {searchResults.length > 0 && searchResults.map((result => (
        <li key={result.id}>
          <img src={result.image} alt="poster" />
          {`${result.text} `}
          <button
            type="button"
            className="plainLink"
            onClick={() => {
              imdbInput.current.value = result.imdbId;
            }}
          >
            {result.imdbId}
          </button>
        </li>
      )))}
    </>
  );
};
export default ImdbForm;

ImdbForm.propTypes = {
  movieId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
