import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google'; // eslint-disable-line import/no-extraneous-dependencies

import MyContext from './components/MyContext';
import DevRibbon from './components/DevRibbon';
import useApp from './hooks/useApp';

import MovieListView from './views/MovieListView';
import YearReport from './views/YearReport';
import TimelineReport from './views/TimelineReport';
import Login from './views/Login';
import Logs from './views/Logs';

import './App.css';

const App = () => {
  const { globalContext } = useApp();
  return (
    <>
      {globalContext !== null
        && (
          <MyContext.Provider value={globalContext}>
            <GoogleOAuthProvider clientId={globalContext.GOOGLE_CLIENTID}>
              <React.StrictMode>
                <DevRibbon />
                <BrowserRouter>
                  <Routes>
                    <Route path="/*">
                      <Route index element={<MovieListView />} />
                      <Route path="timelineReport" element={<TimelineReport />} />
                      <Route path="yearReport" element={<YearReport />} />
                      <Route path="login" element={<Login />} />
                      <Route path="logs" element={<Logs />} />
                    </Route>
                  </Routes>
                </BrowserRouter>
              </React.StrictMode>
            </GoogleOAuthProvider>
          </MyContext.Provider>
        )}
      <span>_</span>
    </>
  );
};

export default App;
