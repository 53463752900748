import React from 'react';
import { ToastContainer } from 'react-toastify';

import AddForm from '../components/AddForm';
import NavBar from '../components/NavBar';
import MovieList from '../components/MovieList';
import PaginationNav from '../components/PaginationNav';
import useMovieListView from '../hooks/useMovieListView';
import { STORAGE_KEY } from '../constants';
import pkg from '../../package.json';

import './MovieListView.css';
import 'react-toastify/dist/ReactToastify.css';

const MovieListView = () => {
  const {
    navdata,
    pageRef,
    viewed,
    searchFormRef,
    form,
    prevBtnDisabled,
    nextBtnDisabled,
    isLoading,
    movies,
    goPageDirection,
    movieAdded,
    toggleViewed,
    debouncedLoadMovies,
  } = useMovieListView();

  const isAuthed = window.localStorage.getItem(STORAGE_KEY) !== 'undefined'
    && window.localStorage.getItem(STORAGE_KEY) !== 'null';

  return (
    <>
      <ToastContainer />
      <NavBar
        toggleViewed={toggleViewed}
        viewed={viewed}
        isAuthed={isAuthed}
        hideToggle={false}
      />
      <div className="main-content">
        <h1>
          {viewed ? (
            <>
              Movie List Seen
              <i className="fa fa-eye icon-spacing" />
            </>
          ) : (
            <>
              Movie List UnSeen
              <i className="fa fa-eye-slash icon-spacing" />
            </>
          )}
        </h1>
        {isAuthed && <AddForm onSuccess={title => movieAdded(title)} />}
        <section className="searchBar">
          <form ref={searchFormRef}>
            <label>Search</label>
            <i className="fa fa-search" />
            <input
              id="searchField"
              type="text"
              name="searchParam"
              onChange={viewed && (() => debouncedLoadMovies())}
            />
            <input type="button" value="clear" onClick={() => { form.clear(); debouncedLoadMovies(); }} />
          </form>
        </section>
        <PaginationNav
          goPageDirection={goPageDirection}
          prevBtnDisabled={prevBtnDisabled}
          nextBtnDisabled={nextBtnDisabled}
          showJumpTo
          navdata={navdata}
          pageRef={pageRef}
          goToAction={debouncedLoadMovies}
        />

        {isLoading && <h2>LOADING</h2>}
        {!isLoading && (
          <MovieList movies={movies} onUpdate={debouncedLoadMovies} />
        )}
        <PaginationNav
          goPageDirection={goPageDirection}
          prevBtnDisabled={prevBtnDisabled}
          nextBtnDisabled={nextBtnDisabled}
          showJumpTo={false}
          navdata={navdata}
        />
      </div>
      <span className="footer-version">
        {`v ${pkg.version}`}
      </span>
    </>
  );
};

export default MovieListView;
