import { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { REST_ENDPOINT, STORAGE_KEY } from '../constants';

function useLoginHook() {
  const [password, setPassword] = useState(null);
  const [isAuthed, setIsAuthed] = useState(false);

  useEffect(() => {
    (async () => {
      if (password) {
        console.log('login#useEffect');
        const endpoint = `${REST_ENDPOINT}/index.php?cmd=login&debug=off`;
        const formData = {
          authId: password.id,
          auth: password.formPass,
        };
        const config = {
          method: 'POST',
          body: JSON.stringify(formData),
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
          },
        };
        try {
          const response = await fetch(endpoint, config);

          if (response.ok) {
            const data = await response.json();
            const authStatus = data.status === true;
            console.log('response authStatus : ', authStatus);
            console.log(data);
            setIsAuthed(authStatus);
            if (authStatus && data.jwt) {
              console.log('writing localstorage');
              window.localStorage.setItem(STORAGE_KEY, data.jwt);
              return;
            }
            if (password.password !== null) {
              toast.error('Invalid password');
            }
            window.localStorage.setItem(STORAGE_KEY, null);
            console.log('clearing localstorage');
            return;
          }
          toast(response.status);
          throw new Error(`loading error : ${response.status}`);
        } catch (error) {
          console.error(error);
          setIsAuthed(false);
        }
      }
    })();
  }, [password]);

  return [isAuthed, setPassword];
}

const useLoginView = () => {
  const password = useRef('');
  const [isAuthed, setPassword] = useLoginHook();
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);

  const login = useGoogleLogin({
    onSuccess: codeResponse => setUser(codeResponse),
    onError: error => console.log('Login Failed:', error),
  });

  const doLogin = async id => {
    console.log('doLogin', id);
    console.log(password.current.value);
    const loginParam = id
      ? { id, formPass: 'n/a' }
      : { formPass: password.current.value };
    console.log('loginParam', loginParam);
    await setPassword(loginParam);
  };

  const logOut = () => {
    window.localStorage.removeItem(STORAGE_KEY);
    googleLogout();
    setProfile(null);
    setPassword('logout');
  };

  useEffect(
    async () => {
      if (!user) {
        console.log('login : clear storage');
        window.localStorage.setItem(STORAGE_KEY, null);
      }
      if (user) {
        try {
          const response = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`);
          const results = await response.json();
          console.log(results);
          setProfile(results);
          if (user.access_token) {
            doLogin(results.id);
          }
          // create security backend that takes an ID
          // encrypt .env files
        } catch (err) {
          console.error(err);
        }
      }
    },
    [user],
  );
  return {
    isAuthed,
    password,
    doLogin,
    profile,
    login,
    logOut,
  };
};

export default useLoginView;
