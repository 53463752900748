import { useState, useEffect } from 'react';
import constants from '../../constants';

export default function useFetch(initialUrl, initialOptions) {
  const [url, setUrl] = useState(initialUrl || null);
  const [options, setOptions] = useState(initialOptions || null);
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (url === null || options === null) {
      return;
    }
    setLoading(true);
    setError(undefined);

    const fetchOptions = {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        'X-App-Token': window.localStorage.getItem(constants.STORAGE_KEY),
      },
    };

    (async () => {
      try {
        const response = await fetch(url, fetchOptions);
        console.log(`response ${response}`);
        if (!response.ok) {
          console.log('response.status :', response.status);
          throw new Error(response.status);
        }
        console.log('response.ok :', response.ok);
        const json = await response.json();
        setData(json);
      } catch (e) {
        setError(e);
        alert(`fetch error : ${e}`);
      } finally {
        setLoading(false);
        setUrl(null);
        setOptions(null);
      }
    })();
  }, [url, options]);

  return {
    data,
    error,
    loading,
    setUrl,
    setOptions,
  };
}
