import React from 'react';
import { Bar } from 'react-chartjs-2';

import {
  Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,
} from 'chart.js';

import NavBar from '../components/NavBar';
import useYearData from '../hooks/useYearData';

import './YearReport.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'right',
    },
    title: {
      display: true,
      text: 'Chart.js Horizontal Bar Chart',
    },
  },
};

const YearReport = () => {
  const { yearDataSet, dowDataSet, monthDataSet } = useYearData();

  return (
    <div>
      <NavBar hideToggle showHome />
      <div className="report-content">
        <h1>Yearly Viewed</h1>
        {yearDataSet && (
          <div className="container-graph">
            <Bar options={options} data={yearDataSet} />
          </div>
        )}
        <h2>Day Of the Week</h2>
        {dowDataSet && (
          <div className="container-graph">
            <Bar options={options} data={dowDataSet} />
          </div>
        )}
        <h2>Month of the Year</h2>

        {monthDataSet && (
          <div className="container-graph">
            <Bar options={options} data={monthDataSet} />
          </div>
        )}
      </div>
    </div>
  );
};

export default YearReport;
