import { useRef, useEffect, useState } from 'react';
import { isValid } from 'date-fns';

import useForm from './useForm';
import {
  REST_ENDPOINT,
  STORAGE_KEY,
} from '../constants';

const useMovieEdit = (movie, onClose) => {
  const movieRef = useRef(movie);

  const [formRef, formFuncs] = useForm();
  const [isLoading, setIsLoading] = useState();

  const saveMovie = async e => {
    e.preventDefault();
    console.log(`save Movie ${JSON.stringify(movie)}`);

    const endpoint = `${REST_ENDPOINT}/index.php?cmd=manage_movie`;
    const token = window.localStorage.getItem(STORAGE_KEY);

    const isValidDate = isValid(new Date(formFuncs.get('dtViewed')));
    console.log(`Date Viewed is valid? ${isValidDate}`);
    if (formFuncs.get('dtViewed') !== '' && !isValidDate) {
      return;
    }

    const entry = {
      action: 'edit',
      movie_id: movie.id,
      title: formFuncs.get('title'),
      dt_viewed: formFuncs.get('dtViewed'),
      comments: formFuncs.get('comments'),
      rating: formFuncs.get('rating'),
      disk: formFuncs.get('disk'),
      format_id: 3,
    };

    const config = {
      method: 'POST',
      body: JSON.stringify(entry),
      headers: {
        'Content-Type': 'application/json',
        'X-App-Token': token,
      },
    };

    try {
      setIsLoading(true);
      const response = await fetch(endpoint, config);
      if (!response.ok) {
        console.error('response.status :', response.status);
        throw new Error(response.status);
      }

      movieRef.current = {
        ...movieRef.current,
        title: formFuncs.get('title'),
        dtViewed: formFuncs.get('dtViewed'),
        comments: formFuncs.get('comments'),
        rating: formFuncs.get('rating'),
        disk: formFuncs.get('disk'),
      };
      console.log(`save Movie Complete ${JSON.stringify(movie)}`);
      setIsLoading(false);
      onClose({ action: 'EDIT', movie: movieRef.current });
    } catch (err) {
      console.log(`Error: ${err}`);
      // toast.error(`loading error : ${err}`);
    }
  };

  // async function imdbReload(id, imdbId) {
  //   console.log('imdbReload :', id, imdbId);
  //   const endpoint =
  // `${REST_ENDPOINT}/index.php?cmd=movie_populate_imdb_info&movie_id=${id}&imdb_id=${imdbId}/`;
  //   const token = window.localStorage.getItem(STORAGE_KEY);
  //   const config = {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'X-App-Token': token,
  //     },
  //   };

  //   const response = await fetch(endpoint, config);
  //   if (!response.ok) {
  //     alert('Error imdbReload');
  //   }
  //   // onUpdate();
  // }

  const deleteMovie = async () => {
    console.log('send delete Movie', movie.id);
    const endpoint = `${REST_ENDPOINT}/index.php?cmd=manage_movie&action=delete&movie_id=${movie.id}`;
    try {
      const response = await fetch(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          'X-App-Token': window.localStorage.getItem(STORAGE_KEY),
        },
      });
      console.log('response :', response);
      if (!response.ok) {
        console.error('response.status :', response.status);
        throw new Error(response.status);
      }
      onClose({ action: 'DELETE', movie: movieRef.current });
    } catch (err) {
      console.log(`Error: ${err}`);
      // toast.error(`loading error : ${err}`);
    }
  };

  function checkKeyPressed(e) {
    console.log(`handle key presss ${e.key}`);
    if (e.altKey && e.key === 's') {
      console.log('saveMovie');
      saveMovie();
    } else if (e.key === 'Escape') {
      console.log('saveMovie');
      onClose({ action: 'CANCEL' });
    }
  }

  useEffect(() => {
    (async () => {
      console.log('useEffect');

      document.addEventListener('keydown', checkKeyPressed);
      return () => document.removeEventListener('keydown', checkKeyPressed);
    })();
  }, []);
  return {
    movieRef, formRef, deleteMovie, saveMovie, isLoading,
  };
};

export default useMovieEdit;
