import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
// import PropTypes from 'prop-types';
import constants from '../constants';
import useFetch from './useFetch';

const useAddForm = onSuccess => {
  const {
    data, error, loading, setOptions, setUrl,
  } = useFetch();
  const isMounted = useRef(false);
  const movieInput = useRef();

  /**
   * It takes the title of the movie to be added, and sends it
   * to the server via a POST request
   */
  async function addMovie() {
    const title = movieInput.current.value;
    if (!title?.length) {
      return;
    }
    setUrl(`${constants.REST_ENDPOINT}/index.php?cmd=manage_movie`);
    console.log('addMovie#e :', title);
    const config = {
      method: 'POST',
      body: JSON.stringify({
        action: 'add',
        title,
        dt_viewed: '',
        rating: '',
        format_id: 3,
      }),
    };
    setOptions(config);
    movieInput.current.value = '';
  }

  useEffect(() => {
    (async () => {
      try {
        console.log('useAddForm ue', loading, data, error);
        if (!isMounted.current) {
          isMounted.current = true;
        } else if (loading) {
          console.log('still sending movie');
        } else if (error) {
          toast(`Error adding Movie : ${data?.title}`);
        } else {
          toast(`Movie added ${data?.title}`);
          onSuccess();
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [loading, data]);

  return {
    addMovie,
    loading,
    error,
    movieInput,
  };
};

export default useAddForm;
