/* eslint-disable quotes */
import React, { useState, useEffect } from 'react';
import NavBar from '../components/NavBar';
import constants from '../constants';

import './Logs.css';

function useLogs() {
  const [logFileName, setLogFileName] = useState('');
  const [logs, setLogs] = useState([]);
  const [logFile, setLogFile] = useState('');

  useEffect(() => {
    (async () => {
      console.log(`#useEffect ${logFileName}`);
      const endpoint = `${constants.REST_ENDPOINT}/index.php?cmd=logs&logFileName=${logFileName}`;
      const token = window.localStorage.getItem(constants.STORAGE_KEY || '');
      const response = await fetch(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-App-Token': token,
          'Cache-Control': 'no-cache',
        },
      });

      if (!response.ok) {
        console.log('response.status :', response.status);
        alert(`loading error : ${response.status}`);
      } else {
        const responseData = await response.json();
        console.log(responseData);
        setLogs(responseData.logs);
        setLogFileName(responseData.logFileName);
        setLogFile(responseData.logFile);
      }
    })();
  }, [logFileName]);

  return [{ logs, logFileName, logFile }, setLogFileName];
}

const Logs = () => {
  const [{ logs, logFileName, logFile }, setLogFile] = useLogs();

  return (
    <>
      <NavBar hideToggle showHome />
      <div className="report-content">
        <h1>Logs</h1>
        <ul>
          {logs.length
            && logs.map(log => (
              <li key={log}>
                <button type="button" onClick={e => setLogFile(e.target.value)} value={log}>
                  {log}
                </button>
              </li>
            ))}
        </ul>
        <h2>
          Log File -
          {logFileName}
        </h2>
        <pre className="text-logs">{logFile}</pre>
      </div>
    </>
  );
};

export default Logs;
