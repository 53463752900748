// # SlideDrawer.js
import React from 'react';
import PropTypes from 'prop-types';

import MovieEdit from './MovieEdit';

import './SlideDrawer.css';

const SlideDrawer = ({
  show,
  movie,
  onClose,
}) => {
  const drawerClasses = show ? 'side-drawer open' : 'side-drawer';

  return (
    <div className={drawerClasses}>
      <MovieEdit
        movie={movie}
        onClose={onClose}
      />
    </div>
  );
};

export default SlideDrawer;

SlideDrawer.propTypes = {
  show: PropTypes.bool.isRequired,
  movie: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
