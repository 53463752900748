import { useState, useEffect } from 'react';
import { REST_ENDPOINT } from '../constants';

const TimelineHook = () => {
  const [reportData, setReportData] = useState(null);

  useEffect(() => {
    (async () => {
      console.log('TimelineHook#useEffect :');
      const endpoint = `${REST_ENDPOINT}/index.php?cmd=graph_watched_timeline&xhr=true`;

      const response = await fetch(endpoint);

      if (!response.ok) {
        console.log('response.status :', response.status);
        alert(`loading error : ${response.status}`);
      } else {
        const data = await response.json();
        console.log('response.data :>> ', data);
        let count = 0;
        const output = data.map(row => ({
          x: row.dt_viewed,
          y: count++,
        }));

        setReportData(output);
      }
    })();
  }, []);
  return [reportData];
};

export default TimelineHook;
