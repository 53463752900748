import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import './NavBar.css';

const NavBar = ({
  showHome, viewed, isAuthed, toggleViewed, hideToggle,
}) => (
  <section className="nav-bar">
    {!hideToggle && (
      <div>
        <NavLink to="/login" className="App-link link-auth">
          {!isAuthed ? 'Login' : 'Logout'}
        </NavLink>
        {viewed ? (
          <button onClick={() => toggleViewed()} type="button">
            UnSeen
            <i className="fa fa-eye-slash icon-spacing" />
          </button>
        ) : (
          <button onClick={() => toggleViewed()} type="button">
            Seen
            <i className="fa fa-eye icon-spacing" />
          </button>
        )}
      </div>
    )}
    <div className="report-links">
      {showHome
        && (
          <NavLink to="/" className="App-link nav-link">
            Home
          </NavLink>
        )}
      <NavLink to="/yearReport" className="App-link nav-link">
        Year Report
      </NavLink>
      <NavLink to="/timelineReport" className="App-link nav-link">
        Timeline Report
      </NavLink>
      <NavLink to="/logs" className="App-link nav-link">
        Logs
      </NavLink>
    </div>
  </section>
);
export default NavBar;

NavBar.defaultProps = {
  showHome: false,
  toggleViewed: () => {},
  viewed: false,
  isAuthed: false,
};

NavBar.propTypes = {
  showHome: PropTypes.bool,
  toggleViewed: PropTypes.func,
  viewed: PropTypes.bool,
  isAuthed: PropTypes.bool,
  hideToggle: PropTypes.bool.isRequired,
};
