import React from 'react';
import PropTypes from 'prop-types';
import { REST_ENDPOINT, STORAGE_KEY } from '../constants';

const ViewedBtn = ({ id, onUpdate }) => {
  const markAsSeen = async () => {
    console.log('markAsSeen :', id);
    const endpoint = `${REST_ENDPOINT}/index.php?cmd=manage_movie&action=mark_as_seen&&movie_id=${id}`;
    const config = {
      headers: {
        'X-App-Token': window.localStorage.getItem(STORAGE_KEY),
      },
    };
    const response = await fetch(endpoint, config);
    if (!response.ok) {
      alert('Error : Mark as Seen');
    }
    onUpdate();
  };

  return (
    <button type="button" onClick={() => markAsSeen()} className="actionBtn">
      <i className="fa fa-eye inline-margin" title="Mark as Viewed" />
      <span>Mark as Viewed</span>
    </button>
  );
};

export default ViewedBtn;

ViewedBtn.propTypes = {
  id: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
