import React from 'react';
import PropTypes from 'prop-types';

const PaginationNav = (
  {
    goPageDirection,
    prevBtnDisabled,
    nextBtnDisabled,
    showJumpTo = false,
    navdata = {},
    pageRef = {},
    goToAction = () => { },
  },
) => {
  const pageList = Array.from({ length: navdata.totalPages }, (_, i) => i + 1);
  const jumpTo = navdata.totalPages > 1 ? (
    <>
      <select ref={pageRef}>
        {pageList.map(index => (<option value={index}>{index}</option>))}
      </select>
      <button onClick={() => goToAction()} type="button">
        <i className="fa fa-file-upload" />
        {' Go to '}
      </button>
    </>
  ) : (
    <input
      type="text"
      ref={pageRef}
      size="3"
      disabled
    />
  );

  return (
    <>
      <section className="buttonRow">
        <button onClick={() => goPageDirection(-1)} type="button" disabled={prevBtnDisabled}>
          <i className="fa fa-chevron-left" />
          Prev
        </button>
        {showJumpTo && jumpTo}
        <button onClick={() => goPageDirection(1)} type="button" disabled={nextBtnDisabled}>
          Next
          <i className="fa fa-chevron-right" />
        </button>
      </section>
      <div>
        {`Total: ${navdata.totalResults}, Page ${navdata.page} of ${navdata.totalPages}`}
      </div>
    </>
  );
};
export default PaginationNav;

// Set default props
PaginationNav.defaultProps = {
  navdata: {},
  pageRef: {},
  goToAction: () => { },
};

PaginationNav.propTypes = {
  goPageDirection: PropTypes.func.isRequired,
  prevBtnDisabled: PropTypes.bool.isRequired,
  nextBtnDisabled: PropTypes.bool.isRequired,
  showJumpTo: PropTypes.bool.isRequired,
  navdata: PropTypes.object,
  pageRef: PropTypes.object,
  goToAction: PropTypes.func,
};
