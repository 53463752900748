import React, { useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import parse from 'date-fns/parse';
import { ToastContainer, toast } from 'react-toastify';

import MyContext from './MyContext';
import Backdrop from './Backdrop';
import SlideDrawer from './SlideDrawer';
import DownloadedBtn from './DownloadedBtn';
import ViewedBtn from './ViewedBtn';
import {
  MOBILE_BREAKPOINT,
  STORAGE_KEY,
} from '../constants';

import 'react-toastify/dist/ReactToastify.css';
import './MovieList.css';

const MovieList = ({ movies, onUpdate }) => {
  const { UPLOAD_ROOT } = useContext(MyContext);
  const [showSideBar, setShowSideBar] = useState(false);
  const movieSelectRef = useRef(null);
  const movieListRef = useRef(movies);

  const isMobile = window.innerWidth < MOBILE_BREAKPOINT;
  const isAuthed = window.localStorage.getItem(STORAGE_KEY) !== 'undefined'
  && window.localStorage.getItem(STORAGE_KEY) !== 'null';

  console.log('movielist auth check', isAuthed);

  function externalLink(url) {
    window.open(url, '_blank');
  }

  const sideBarClosed = status => {
    let itemIndex = -1;
    switch (status.action) {
      case ('DELETE'):
        movieListRef.current = movieListRef.current.filter(x => x.id !== status.movie.id);
        setShowSideBar(!showSideBar);
        toast(`Deleted ${status.movie.title}`);
        break;
      case ('EDIT'):
        console.log(status.movie);
        itemIndex = movieListRef.current.findIndex(el => el.id === status.movie.id);
        console.log(itemIndex);
        movieListRef.current[itemIndex] = status.movie;
        setShowSideBar(!showSideBar);
        toast(`${status.movie.title} edited`);
        break;
      default:
        setShowSideBar(!showSideBar);
    }
  };

  return (
    <>
      <ToastContainer />
      {showSideBar && (
        <>
          <SlideDrawer
            show={showSideBar}
            movie={movieSelectRef.current}
            onClose={sideBarClosed}
          />
          <Backdrop
            close={
            () => setShowSideBar(!showSideBar)
            }
          />
        </>
      )}
      {movieListRef.current.map(entry => {
        let styling = 'row-movie';
        const isFutureRelease = new Date(entry.dtReleasedFull).getTime() > new Date().getTime();
        // console.log(entry.title,entry.dtReleasedFull, 'compare :', isFutureRelease);

        switch (true) {
          case entry.comments !== null && entry.comments.indexOf('#dl') > -1:
            styling += ' row-movie-downloaded';
            break;
          case entry.dtViewed !== null && entry.dtViewed !== '0000-00-00':
            styling += ' row-movie-viewed';
            break;
          case isFutureRelease:
            styling += ' row-movie-future-release';
            break;
          default:
            break;
        }

        const isViewed = entry.dtViewed !== '';

        return (
          <section key={entry.id} className={styling}>
            <img
              src={`${UPLOAD_ROOT}/${entry.imdbImageId}.jpg`}
              alt={entry.title}
            />
            <div>
              <div className="title-row">
                <button
                  onClick={() => { movieSelectRef.current = entry; setShowSideBar(!showSideBar); }}
                  type="button"
                  className="plainLink"
                >
                  {entry.title}
                  {entry.dtReleasedFull !== '1900-01-01' && (
                    <>
                      {' '}
                      (
                      {parse(
                        entry.dtReleasedFull,
                        'yyyy-MM-dd',
                        new Date(),
                      ).getFullYear()}
                      )
                    </>
                  )}
                </button>
                {!isViewed && (!entry.comments || entry.comments.indexOf('#dl') <= -1) && (
                  // Show Links for Unseen, Not downloaded movies
                  <DownloadedBtn id={parseInt(entry.id, 10)} onUpdate={onUpdate} />
                )}
              </div>
              <div>
                {entry.imdbId && entry.imdbId.length ? (
                  <>
                    <button
                      title="Imdb"
                      onClick={() => externalLink(`https://www.imdb.com/title/${entry.imdbId}/`)}
                      className="imagelink sprite-imdb inline-margin"
                      type="button"
                    >
                      Imdb
                    </button>
                    <span>
                      {entry.imdbRating}
                      {' '}
                      |
                      {' '}
                      {entry.imdbGenre}
                    </span>
                    <span>
                      <i className="fa fa-running icon-running" />
                      :
                      <span className="text-inline">
                        {entry.runtime}
                      </span>
                    </span>
                    {/*
                    TODO
                    <button
                      type="button"
                      onClick={() => imdbReload(entry.id, entry.imdbId)}
                    >
                      Reload
                    </button> */}
                  </>
                ) : (
                  <i className="fa fa-exclamation-circle inline-margin" />
                )}
              </div>
              {entry.rtId && entry.rtId.length && (
                <div>
                  <button
                    title="Imdb"
                    onClick={() => externalLink(`https://www.rottentomatoes.com/m/${entry.rtId}/`)}
                    className="imagelink sprite-rottentomatoes inline-margin"
                    type="button"
                  >
                    RT
                  </button>
                  <span>
                    {entry.rtTmeterRating}
                    |
                    {entry.rtCommunityRating}
                  </span>
                </div>
              )}
              <div>
                {isViewed ? (
                  <>
                    <i className="fa fa-eye inline-margin" />
                    <span className="text-inline">
                      {'Viewed '}
                      {entry.dtViewed}
                      {', Rating: '}
                      {entry.rating}
                    </span>
                  </>
                ) : (
                  <>
                    {!isAuthed && <span>NoAuth</span>}
                    <ViewedBtn id={parseInt(entry.id, 10)} onUpdate={onUpdate} />
                  </>
                )}
                {isViewed && entry.comments.length > 0 && (
                  <i
                    className="fa fa-comment-dots inline-margin"
                    title={entry.comments.replace(/(<([^>]+)>)/gi, '')}
                  />
                )}
                {!isViewed && entry.imdbId && !isMobile && (
                  // Show Links for Unseen, Not downloaded movies
                  <Link
                    to="/"
                    onClick={() => externalLink(`https://rarbg.to/torrents.php?imdb=${entry.imdbId}`)}
                    className="inline-margin"
                  >
                    <i className="fa fa-magnet inline-margin" />
                    <span>rarbg imdb</span>
                  </Link>
                )}

                {entry.storyline && entry.storyline.length > 0 && (
                  <p className="text-storyline">
                    {entry.storyline}
                  </p>
                )}
              </div>
            </div>
          </section>
        );
      })}
    </>
  );
};
export default MovieList;

MovieList.propTypes = {
  movies: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
