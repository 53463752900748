import React from 'react';
import PropTypes from 'prop-types';
import useAddForm from '../hooks/useAddForm';

import './AddForm.css';

const AddForm = ({ onSuccess }) => {
  const {
    addMovie, loading, error, movieInput,
  } = useAddForm(onSuccess);

  return (
    <div className="addForm">
      <form onSubmit={e => { addMovie(); e.preventDefault(); }}>
        <label>
          AddForm
          <input
            data-testid="titleInput"
            id="addField"
            name="title"
            placeholder="new movie"
            ref={movieInput}
            type="text"
          />
        </label>
        <button
          className="btn btn-primary"
          data-testid="addBtn"
          id="addBtn"
          type="submit"
        >
          <i className="fa fa-save" />
          Submit
        </button>
      </form>
      {loading && (
        <div className="AlertText">LOADING</div>
      )}
      {error && <div className="AlertText">{JSON.stringify(error)}</div>}
    </div>
  );
};

export default AddForm;

AddForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
