import React from 'react';
import 'chartjs-adapter-date-fns';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import NavBar from '../components/NavBar';
import useTimelineHook from '../hooks/TimelineHook';

import './TimelineReport.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day',
      },
    },
    y: {
      beginAtZero: true,
    },
  },
};

const ViewedTimeline = () => {
  const [reportData] = useTimelineHook();

  let timeline = null;

  if (reportData !== null) {
    console.log('reportData :>> ', reportData);
    timeline = {
      labels: reportData.map(x => x.x),
      datasets: [
        {
          label: 'Dataset B',
          data: reportData,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
      ],
    };
  }

  return (
    <>
      <NavBar hideToggle showHome />
      <div className="report-content">
        <h1>Timeline</h1>
        {timeline !== null && (
          <div>
            <Line options={options} data={timeline} />
          </div>
        )}
      </div>
    </>
  );
};

export default ViewedTimeline;
